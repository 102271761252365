import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import festivalImage from '../assets/Republicdaysplash.gif';
import { getCookie, setCookie } from '../utils/storageHelpers';
// import { Snowfall } from 'react-snowfall';

export default function AnnouncementWrapper({ children }) {
  const { width, height } = useWindowSize();
  const [showAnnouncement, setShowAnnouncement] = useState(false);

  useEffect(() => {
    const importantDates = ['1/25', '1/26'];
    if (getCookie('holiFlag') != 'shown') {
      setShowAnnouncement(setFlagForDate(importantDates));
    }
    setTimeout(() => {
      setShowAnnouncement(false);
      setCookie('holiFlag', 'shown', 7);
    }, 5000);
  }, []);

  function setFlagForDate(targetDates) {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based, so we add 1

    // Format current date to match target dates
    const formattedCurrentDate = `${currentMonth}/${currentDay}`;

    // Check if the current date matches any of the target dates
    if (targetDates.includes(formattedCurrentDate)) {
      return true; // Set flag to true if current date matches
    } else {
      return false; // Set flag to false otherwise
    }
  }

  return (
    <>
      {showAnnouncement ? (
        <div>
          {/* <Snowfall color="white" snowflakeCount={300} style={{ zIndex: 30 }} /> */}
          <Transition appear show={true} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => null}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-65" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="relative w-full max-w-xl px-4 pt-4 overflow-visible text-left align-middle transition-all transform rounded-2xl">
                      <img src={festivalImage} alt="side_image" className="m-auto" />
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
          <div className="z-20">
            <Confetti width={width} height={height}>
              {children}
            </Confetti>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
}
