import { ERROR_PATH, REGION_GATEWAY, UNAUTHORIZED_PATH } from '../utils/constants';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  PrimaryLink,
  H3,
} from './twEssential';
import React, { useEffect, useState } from 'react';
import SearchBar from './SearchBar';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setFragmentData } from '../features/screen/screenSlice';
import loadingImage from '../animations/loadingPageImg.json';
import Lottie from 'lottie-react';
import { sendRequest } from '../utils/apiCall';
import { Icons } from '@regere-ai/blocks-v2';
import Actions from './Actions';
import _ from 'lodash';
import { triggerNotification } from '../features/screen/notificationSlice';
import {
  computePayload,
  constructPayLoadData,
  constructURLQueryString,
  getAvatarText,
  getFragmentKey,
} from '../utils/helperFunctions';
import { formatValue } from '../utils/helperFunctions';
import { ConfirmationPopup } from './ConfirmationPopup';
import AddIcon from '../logo/add_new_icon.png';
import Tooltip from './Tooltip';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import { capitalize } from './../utils/captilizeHelper';
import pluralize from 'pluralize';
import { Filter } from './Filter';
import LayoutFragment from './LayoutFragment';
import eventBus from '../services/EventBus';
import { Gupagination } from '@regere-ai/blocks-v2';

let headerWithoutDollarDot, entityData;

export function EmptyList(props) {
  const {
    data,
    actions,
    entity,
    params,
    name,
    showSubText,
    allowBulkCreateFlag,
    allowCreateFlag,
    EmptyData,
    EmptyDataIcon,
    customMessage,
    className,
  } = props;
  let singularPageTitle;
  if (data) {
    if (data?.popUpTableTitle) {
      singularPageTitle = pluralize.singular(data?.popUpTableTitle);
    } else if (data?.pageTitle) {
      singularPageTitle = pluralize.singular(data.pageTitle);
    } else if (data?.field) {
      singularPageTitle = pluralize.singular(data.field);
    }
  }

  return (
    <div className="flex flex-col justify-center text-center mt-5">
      <img
        className={`mx-auto ${
          data ? (data.field ? 'h-28 w-28 my-3' : 'h-48 w-48 my-5') : 'h-28 w-28 my-3'
        }`}
        src={EmptyData ? EmptyDataIcon : AddIcon}
        alt={EmptyData ? 'NoData Icon' : 'AddIcon'}
      />
      <h3 className={className ? className : 'mt-2 text-md font-semibold text-gray-900'}>
        No{' '}
        {customMessage
          ? customMessage
          : name
          ? `${name}s`
          : data?.field
          ? data?.field
          : data?.pageTitle
          ? data?.pageTitle
          : `${singularPageTitle}s`}
      </h3>
      {showSubText && (
        <p className="mt-1 text-md text-gray-500">
          Get started by creating a new {name ? name : singularPageTitle}.
        </p>
      )}
      <div className="mt-6">
        {data?.pageTitle ? (
          <div className="flex justify-center items-center">
            {allowBulkCreateFlag ? (
              <Actions
                data={{
                  id: 'action_bulkUpload',
                }}
                actions={actions}
                entity={entity}
                params={params}
              />
            ) : null}
            {allowBulkCreateFlag ? <span>OR</span> : null}
            {allowCreateFlag ? (
              <Actions
                data={{
                  id: 'action_create',
                }}
                actions={actions}
                entity={entity}
                params={params}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default function ListTable(props) {
  const { data, actions, entity, params } = props;
  const navigate = useNavigate();
  var {
    tableHeaders,
    dataLoader,
    rowActions,
    allowCreate,
    allowBulkCreate,
    allowBulkExport,
    allowSearch,
    allowFilter,
    FilterOptions,
    defaultOption,
    defaultFilterOptions,
    allowUpload,
    retriveFromKey,
    tableHeaderOverrides,
    tableSameCellDatas,
    pagination,
  } = data;
  if (props?.fields) {
    entity.fields = _.merge({}, entity.fields, props.fields);
  }
  const [instances, setInstances] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  // const [archiveDialog, setArchiveDialog] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchFilterHasResults, setSearchFilterHasResults] = useState(false);
  const [rowInstance, setRowInstance] = useState(null);
  const [deleteRowIndex, setDeleteRowIndex] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const [count, setCount] = useState(null);
  const [selectedPageCount, setSelectedPageCount] = useState(10);
  const dispatch = useDispatch();
  let allowCreateFlag = typeof allowCreate === 'undefined' ? true : allowCreate;
  let allowBulkCreateFlag = typeof allowBulkCreate === 'undefined' ? true : allowBulkCreate;
  let allowBulkExportFlag = typeof allowBulkExport === 'undefined' ? false : allowBulkExport;
  const fragmentKey = getFragmentKey(params);
  let fragmentData = useSelector((state) => state.screen.data?.[fragmentKey]);
  let dynamicFragmentData = { ...fragmentData };
  const rowsPerPage = selectedPageCount;
  const startIndex = (currentPage - 1) * rowsPerPage;
  let elseIndex = 0;

  const [searchText, setSearchText] = useState('');
  const [filterDialog, setFilterDialog] = useState(false);
  const [trackerFilterDialog, setTrackerFilterDialog] = useState(false);
  const [trackerFilter, setTrackerFilter] = useState({});
  const [ticketDetails, setTicketDetails] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState();
  const [selectedType, setSelectedType] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState('');
  const [hasMultipleFilterOptions, setHasMultipleFilterOptions] = useState(false);

  // Get the rows for the current page
  let currentPageData, retrivedValue;

  let transformedHeader;
  !searchFilterHasResults
    ? (currentPageData = instances)
    : (currentPageData = instances?.slice(startIndex, startIndex + rowsPerPage));

  /**TODO : 
     * "tableHeaders": [
     "$.inputBom.materialId",
     "$.inputBom.material",
     "$.inputBom.type",
     "$.inputBom.critical",
     "$.inputBom.quantity"
   ],

   "retriveFromKey": "inputBom",
   As of now , retriveFromKey is handled for single value , for multiple we need to handle

   For Eg : 
     * 
   * "tableHeaders": [
     "$.inputBom.materialId",
     "$.inputBom.material",
     "$.status.type",
     "$.status.critical",
     "$.status.quantity"
   ],
     */
  let retriveValueStore = data?.retriveFromKey;
  useEffect(() => {
    if (retriveValueStore) {
      setInstances(dynamicFragmentData?.[retriveValueStore]);
    }
  }, [dynamicFragmentData, retriveValueStore]);

  useEffect(() => {
    const getListAPI = async () => {
      let URL = `${REGION_GATEWAY}/${dataLoader?.service}/instances/${dataLoader?.sId}`;

      if (searchFilterHasResults) {
        URL += `?searchText=${searchText}`; // Use search text if there are search results
      } else if (dataLoader?.filter?.length) {
        URL += `?${dataLoader?.filter}`;
      }

      URL += `${searchFilterHasResults || dataLoader?.filter?.length ? '&' : '?'}page=${
        currentPage - 1
      }&limit=${rowsPerPage}`;

      const { response, responseError } = await sendRequest({
        url: URL,
        method: 'GET',
      });

      if (response && response?.data?.data) {
        const ticketDetailsArr = response?.data?.data?.instances || [];
        setInstances(ticketDetailsArr);
        setTicketDetails(ticketDetailsArr);
        setCount(response?.data?.data?.meta?.count);
      } else {
        if (responseError?.response?.status === 401) {
          navigate(UNAUTHORIZED_PATH);
        } else {
          navigate(ERROR_PATH);
        }
      }
    };

    if (dataLoader) {
      getListAPI();
    }
  }, [
    currentPage,
    rowsPerPage,
    searchFilterHasResults,
    dataLoader,
    searchText,
    selectedFilterOptions,
  ]);

  async function onArchiveHandler(uId) {
    try {
      let { response } = await sendRequest({
        url: `${REGION_GATEWAY}/${dataLoader?.service}/archiveinstances/${dataLoader?.sId}/${uId}`,
        method: 'PATCH',
      });
      let responseData = await sendRequest({
        url: `${REGION_GATEWAY}/${dataLoader?.service}/instances/${dataLoader?.sId}`,
        method: 'GET',
      });

      console.log(response.data.data);
      if (response && response.data.data) {
        dispatch(triggerNotification(response.data.message, 'success'));
      } else {
        dispatch(triggerNotification(response.data.message, 'error'));
      }

      setInstances(responseData.response.data.data.instances);
    } catch (error) {
      console.error(error);
    }
  }

  const handlePageChange = (page: any, rows: any) => {
    setSelectedPageCount(rows);
    setCurrentPage(page);
  };

  const onDeleteHandler = (index) => {
    const updatedDynamicFragmentData = [...dynamicFragmentData[retriveValueStore]];
    updatedDynamicFragmentData.splice(index, 1);
    dispatch({
      ...setFragmentData({ ...fragmentData, [retriveValueStore]: updatedDynamicFragmentData }),
      fragmentKey: fragmentKey,
    });
    console.log(updatedDynamicFragmentData);
  };

  const performAction = (action, instance, index) => {
    if (action?.goto?.type === 'layout') {
      dispatch(setLoading(true));
      if (action.goto.id) {
        // Explicit case. Handled it separately
        const queryString = constructURLQueryString(action.goto.query, instance);
        if (typeof action.goto.sId === 'object') {
          let combinedSID;
          let extendedEntityNestedKey = action.goto.sId.params[1].split('.');
          if (extendedEntityNestedKey.length > 1) {
            combinedSID = computePayload({
              ...action.goto.sId,
              plugData: instance[extendedEntityNestedKey[0]][extendedEntityNestedKey[1]],
            });
          } else {
            combinedSID = computePayload({
              ...action.goto.sId,
              plugData: instance[extendedEntityNestedKey[0]],
            });
          }
          navigate(
            `/${action.goto.service}/${combinedSID}/${instance[action.goto.id]}/${
              action.goto.screen
            }?${queryString}`,
          );
        } else {
          if (action?.goto?.openNewTab) {
            window.open(
              `/${action.goto.service}/${action.goto.sId}/${instance[action.goto.id]}/${
                action.goto.screen
              }?${queryString}`,
              `_blank`,
            );
          } else {
            navigate(
              `/${action.goto.service}/${action.goto.sId}/${instance[action.goto.id]}/${
                action.goto.screen
              }?${queryString}`,
            );
          }
        }
      } else {
        const queryString = constructURLQueryString(action.goto.query, instance);
        if (typeof action.goto.sId === 'object') {
          let combinedSID;
          let extendedEntityNestedKey = action.goto.sId.params[1].split('.');
          if (extendedEntityNestedKey.length > 1) {
            combinedSID = computePayload({
              ...action.goto.sId,
              plugData: instance[extendedEntityNestedKey[0]][extendedEntityNestedKey[1]],
            });
          } else {
            combinedSID = computePayload({
              ...action.goto.sId,
              plugData: instance[extendedEntityNestedKey[0]],
            });
          }
          navigate(`/${action.goto.service}/${combinedSID}/${action.goto.screen}?${queryString}`);
        } else {
          navigate(
            `/${action.goto.service}/${action.goto.sId}/${action.goto.screen}?${queryString}`,
          );
        }
      }
    } else if (action?.goto?.type === 'triggerAPI' && action.goto.askConfirmation) {
      setRowInstance(instance);
      setShowConfirmationPopup(action.goto.askConfirmation);
    } else if (action?.goto?.type === 'requestV2') {
      triggerRequestV2(action.goto, instance);
    } else if (action?.deleteFromPopupOpenerTable) {
      setDeleteRowIndex(index);
      setShowDeleteConfirmationPopup(true);
    } else if (action.type === 'fragment') {
      setSelectedAction(action);
      dispatch({
        ...setFragmentData({
          ...fragmentData,
          instance,
        }),
        fragmentKey: action.dataSourceKey,
      });
    } else if (action.type === 'fragmentEdit') {
      const updatedInstance = { ...instance, editable: true, index };
      setSelectedAction(action);
      dispatch({
        ...setFragmentData(updatedInstance),
        fragmentKey: action.dataSourceKey,
      });
    } else {
      alert(`WIP: ${action.goto.type}`);
    }
  };

  const triggerRequestV2 = async (goto, instance) => {
    try {
      let { response } = await sendRequest({
        url: `${REGION_GATEWAY}/${dataLoader?.service}/instances`,
        method: 'POST',
        body: {
          sId: dataLoader?.sId,
          data: constructPayLoadData(goto.body.data, instance),
        },
      });

      if (response.status === 200) {
        dispatch(triggerNotification(response.data.message, 'success'));
      }
    } catch {
      console.log('error');
    }
  };

  const onSearchInputChangeHandler = async (e) => {
    const value = e.target.value;
    const filterQuery = `searchText=${value}`;
    const page = currentPage - 1;

    if (value.length >= 3) {
      const URL = `${REGION_GATEWAY}/${dataLoader?.service}/instances/${dataLoader?.sId}?${filterQuery}&page=${page}&limit=${rowsPerPage}`;

      let { response } = await sendRequest({
        url: URL,
        method: 'GET',
      });

      setInstances(response?.data?.data?.instances || []);
      setCount(response?.data?.data?.meta?.count || 0);
      setSearchFilterHasResults(true);
    } else if (value.length === 0) {
      const URL = `${REGION_GATEWAY}/${dataLoader?.service}/instances/${dataLoader?.sId}?${
        dataLoader?.filter || ''
      }&page=${page}&limit=${rowsPerPage}`;
      let { response } = await sendRequest({
        url: URL,
        method: 'GET',
      });

      setInstances(response?.data?.data?.instances || []);
      setCount(response?.data?.data?.meta?.count || 0);
      setSearchFilterHasResults(false);
    }
  };

  const renderCells = (_instance, tableSameCellDatas, header, i) => {
    const params = tableSameCellDatas?.[header]?.goto;
    if (tableSameCellDatas?.[header]?.values[0] === 'fullName') {
      return `${capitalize(_instance['firstName'])} ${capitalize(_instance['lastName'])}`;
    }

    switch (header) {
      case 'project':
        return (
          <button
            onClick={() => {
              navigate(`/${params?.service}/${params?.sId}/${_instance?._id}/${params.screen}`);
            }}
            className="text-base font-semibold text-[#6661FA]"
          >
            {_instance[tableSameCellDatas?.[header]?.values[0]]}
          </button>
        );

      default:
        if (typeof tableSameCellDatas?.[header]?.values[0] === 'string') {
          const transformedHeader = tableSameCellDatas?.[header]?.values[0].split('.');
          if (transformedHeader.length === 2) {
            let transformedHeaderInstance = _instance[transformedHeader[0]];
            return transformedHeaderInstance?.[transformedHeader[1]] || ''; // Handling data inconsistency
          } else {
            return _instance[tableSameCellDatas?.[header]?.values[0]];
          }
        } else if (typeof tableSameCellDatas?.[header]?.values[0] === 'object') {
          const transformedHeader = tableSameCellDatas?.[header]?.values[0];
          return renderCellComponent(_instance, transformedHeader, i);
        }
    }
  };
  function getFormattedValue(_instance, key) {
    if (key === 'updatedAt' || key === 'createdAt') {
      if (_instance[key]) {
        return formatValue(_instance[key], 'DD-MMM-YYYY hh:mm A', 'Date');
      }
    } else {
      if (typeof key === 'string') {
        return _instance[key];
      } else if (typeof key === 'object') {
        return renderCellComponent(_instance, key);
      }
    }
  }

  const renderCellComponent = (_instance, key, i) => {
    switch (key.renderFunction) {
      case 'renderInfoIcon':
        if (_instance) {
          const contextVars = _instance?.contextVars;
          const customFieldContextVars = {};
          for (const uId in contextVars) {
            const customField = customFields.find((field) => field.uId === uId);
            if (customField) {
              // TODO: Temperary fix,Need framework level changes for this.
              if (customField.type !== 'Internal Lookup') {
                customFieldContextVars[customField.title] = contextVars[uId];
              }
            }
          }

          return (
            <div className="flex gap-3 items-center">
              <div>{_instance[key.value]} </div>
              <div id={`field-${_instance?._id}`}>
                <InformationCircleIcon className="h-5 w-5 text-slate-400" aria-hidden="true" />
                {_instance.contextVars &&
                Object.keys(_instance.contextVars).length > 0 &&
                Object.keys(customFieldContextVars).length > 0 ? (
                  <Tooltip content={customFieldContextVars} type="multiple" id={_instance?._id} />
                ) : (
                  <Tooltip content={'No data'} type="tooltipSingle" id={_instance?._id} />
                )}
              </div>
            </div>
          );
        }
        break;

      case 'renderAsBadges':
        if (_instance) {
          const badges = _instance[key.value];
          const formatControls = key;
          const badgeElements = badges?.map((_badge) => _badge[formatControls.displayLabel]);
          // Filter out empty elements
          const nonEmptyBadgeElements = badgeElements?.filter(
            (element) => element !== undefined && element !== null && element !== '',
          );
          // Define the maximum number of badges to show initially
          const maxBadgesToShow = 3;
          // Split the badges into two arrays: the first 3 and the rest
          const firstThreeBadges = nonEmptyBadgeElements?.slice(0, maxBadgesToShow);
          const remainingBadges = nonEmptyBadgeElements?.slice(maxBadgesToShow);
          // Map the first three elements to JSX badges
          const firstThreeBadgeComponents = firstThreeBadges?.map((element, index) => (
            <span
              key={index}
              className="bg-indigo-100 text-indigo-700 text-sm rounded-full px-4 py-2 mr-2"
            >
              {element}
            </span>
          ));
          let remainingBadgeLabel = null;
          if (remainingBadges?.length > 0) {
            remainingBadgeLabel = ` +${remainingBadges?.length}`;
          }

          return (
            <div>
              {firstThreeBadgeComponents}
              {remainingBadgeLabel && (
                <span className="text-gray-600 text-sm">{remainingBadgeLabel}</span>
              )}
            </div>
          );
        }

        break;
      case 'renderDateFormat':
        if (_instance[key.value]) {
          return formatValue(_instance[key.value], 'DD-MMM-YYYY', 'Date');
        }
        break;

      case 'renderPrefix':
        if ((key?.params).length) {
          return `${key.params[0]}${_instance[key.value]}`;
        }
        break;
      case 'renderBoolean':
        if ((key?.params).length) {
          if (_instance[key.value] === key?.params[0]) {
            return <CheckIcon className="h-6 w-6 text-green-800" />;
          } else if (_instance[key.value] === key?.params[1]) {
            return <XMarkIcon className="h-6 w-6 text-red-800" />;
          }
        }
        break;

      default:
        return key?.value;
    }
  };

  async function fetchData() {
    let { response } = await sendRequest({
      url: `${REGION_GATEWAY}/coreV2/instances/custom_field`,
      method: 'GET',
    });
    if (response?.data?.data?.instances?.length) {
      setCustomFields(response?.data?.data?.instances);
    }
  }
  if (!selectedFilterOptions && allowFilter) {
    setSelectedOption(defaultOption);
    let filter = defaultFilterOptions;
    setSelectedFilterOptions(filter);
  }
  useEffect(() => {
    getTicketData();
  }, [selectedFilterOptions, allowFilter]);

  const getTicketData = async () => {
    let URL = `${REGION_GATEWAY}/${params?.service}/instances/${params?.sId}`;
    if (selectedFilterOptions?.length) {
      selectedFilterOptions?.forEach((filter, index) => {
        URL = `${URL}${index === 0 ? '?' : '&'}${filter.type}=${filter.value}`;
      });
    }
    const { response } = await sendRequest({
      url: URL,
      method: 'GET',
    });
    const ticketDetailsArr = response?.data?.data?.instances ? response?.data?.data?.instances : [];
    setSearchFilterHasResults(true);
    setInstances(ticketDetailsArr);
    setTicketDetails(ticketDetailsArr);
    return ticketDetailsArr;
  };

  const getProjectDetails = async () => {
    const { response } = await sendRequest({
      url: `${REGION_GATEWAY}/coreV2/instances/project`,
      method: 'GET',
    });

    const projectDetailsArr = response?.data?.data?.instances;
    setProjectDetails(projectDetailsArr);
    return projectDetailsArr;
  };

  useEffect(() => {
    getProjectDetails();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getProjectDetails();
    if (selectedFilterOptions?.length >= 1) {
      setHasMultipleFilterOptions(true);
    } else {
      getProjectDetails();
      setHasMultipleFilterOptions(false);
    }
  }, [selectedFilterOptions]);

  useEffect(() => {
    if (hasMultipleFilterOptions) {
      getTicketData();
    }
  }, [selectedFilterOptions, hasMultipleFilterOptions]);

  const getSelectedOption = (
    selectedOption,
    selectedDateRange,
    selectedType,
    selectedAssignee,
    projectDetails,
  ) => {
    let filters = [...selectedFilterOptions];
    let index = filters?.findIndex((e) => e?.type === selectedOption?.type);
    if (index === -1) {
      let obj = {
        type: selectedOption?.type,
        typeLabel: selectedOption?.label,
        value: selectedOption.type === 'dateRange' ? selectedDateRange : projectDetails?.value,
        valueLabel:
          selectedOption?.type === 'dateRange' ? selectedDateRange : projectDetails?.label,
      };
      filters.push(obj);
    } else {
      filters[index].value =
        selectedOption?.type === 'dateRange' ? selectedDateRange : selectedAssignee?.value;
      filters[index].valueLabel =
        selectedOption?.type === 'dateRange' ? selectedDateRange : projectDetails?.label;
    }
    setSelectedFilterOptions(filters);
  };
  const RemoveFilter = (type) => {
    let filters = [...selectedFilterOptions];
    let newFilter = filters?.filter((e) => e?.type !== type);
    getTicketData();
    getProjectDetails();
    setSelectedOption({});
    setSelectedFilterOptions(newFilter);
  };

  const resetState = (data) => {
    eventBus.emit('refreshComponent', selectedAction.item);
    setSelectedAction(null);
  };

  if (instances) {
    return (
      <>
        {trackerFilterDialog && (
          <Filter
            filterDialog={filterDialog}
            setFilterDialog={setFilterDialog}
            setTicketDetails={setTicketDetails}
            ticketDetails={ticketDetails}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            getSelectedOption={getSelectedOption}
            setSelectedDateRange={setSelectedDateRange}
            selectedDateRange={selectedDateRange}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            getProjectDetails={getProjectDetails}
            defaultFilterOptions={defaultFilterOptions}
            projectDetails={projectDetails}
            setProjectDetails={setProjectDetails}
            selectedAssignee={selectedAssignee}
            setSelectedAssignee={setSelectedAssignee}
            ticketTypes={data?.ticketTypes}
            showDialog={trackerFilterDialog}
            setDialog={setTrackerFilterDialog}
            trackerFilter={trackerFilter}
            setTrackerFilter={setTrackerFilter}
            FilterOptions={FilterOptions}
            params={params}
          />
        )}

        {instances.length > 0 ? (
          <div className="px-4 sm:px-6 lg:px-8 mt-3">
            {allowCreateFlag || allowBulkCreateFlag || allowBulkExportFlag || data?.pageTitle ? (
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h4 className="font-semibold text-slate-900 text-2xl">{data.pageTitle}</h4>
                </div>
                <div className="flex flex-row mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  {allowBulkCreateFlag ? (
                    <div className="flex">
                      <Actions
                        data={{
                          id: 'action_bulkUpload',
                        }}
                        actions={actions}
                        entity={entity}
                        params={params}
                      />
                    </div>
                  ) : null}
                  {allowBulkExportFlag ? (
                    <div className="flex">
                      <Actions
                        data={{
                          id: 'action_export',
                        }}
                        actions={actions}
                        entity={entity}
                        params={params}
                      />
                    </div>
                  ) : null}
                  {allowCreateFlag ? (
                    <div className="flex">
                      <Actions
                        data={{
                          id: 'action_create',
                        }}
                        actions={actions}
                        entity={entity}
                        params={params}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            <div className="mt-8 flow-root">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6">
                  {(() => {
                    if (data?.title) {
                      return (
                        <div className="p-2 divide-y divide-slate-300">
                          <H3>{data?.title}</H3>
                        </div>
                      );
                    }

                    return null;
                  })()}

                  <div
                    className={`${
                      !data.allowSearch && data.pagination.limit === '-1'
                        ? ''
                        : 'rounded-lg bg-white shadow p-3'
                    }`}
                  >
                    <div className="flex items-center justify-end">
                      {allowFilter ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 cursor-pointer"
                            onClick={() => {
                              console.log('SVG clicked');
                              setTrackerFilterDialog(true);
                            }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                            />
                          </svg>
                        </>
                      ) : null}
                      {allowSearch ? (
                        <>
                          <SearchBar
                            onSearchInputChangeHandler={(e) => {
                              onSearchInputChangeHandler(e);
                              setSearchText(e.target.value);
                            }}
                            searchValue={searchText}
                          />
                        </>
                      ) : null}
                    </div>
                    {allowFilter && (
                      <div className="flex flex-row p-2 m-2 bg-white items-center">
                        <p className="pr-6 text-gray-400 text-sm font-bold">FILTERS</p>
                        {selectedFilterOptions?.map((filter, index) => (
                          <div className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-gray-100 rounded-full border">
                            <div className="text-xs font-normal leading-none max-w-full flex-initial">
                              <span>
                                {filter?.typeLabel}: {filter?.valueLabel}
                              </span>
                            </div>
                            <div className="flex flex-auto flex-row-reverse">
                              <div
                                onClick={() => {
                                  RemoveFilter(filter.type);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="100%"
                                  height="100%"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-x cursor-pointer hover:text-indigo-400 rounded-full w-4 h-4 ml-2"
                                >
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                      <Table>
                        <TableHead>
                          {data.type === 'listTable' ? (
                            <TableRow>
                              {tableHeaders.map((header) => {
                                if (header === 'createdAt') {
                                  return <TableHeader key={header}>{'CREATED AT'}</TableHeader>;
                                } else if (header === 'updatedAt') {
                                  return <TableHeader key={header}>{'UPDATED AT'}</TableHeader>;
                                } else if (header === 'createdBy.fullName') {
                                  return (
                                    <TableHeader key={header}>
                                      {tableHeaderOverrides[header]
                                        ? _.toUpper(tableHeaderOverrides[header].title)
                                        : 'CREATED BY'}
                                    </TableHeader>
                                  );
                                } else if (header === 'updatedBy.fullName') {
                                  return (
                                    <TableHeader key={header}>
                                      {tableHeaderOverrides[header]
                                        ? _.toUpper(tableHeaderOverrides[header].title)
                                        : 'UPDATED BY'}
                                    </TableHeader>
                                  );
                                } else if (tableSameCellDatas?.[header]) {
                                  return (
                                    <TableHeader key={header}>
                                      {tableSameCellDatas[header]?.title.toUpperCase()}
                                    </TableHeader>
                                  );
                                }
                                // header can have values like customFieldSID.name. Need to handle this case
                                if (entity.fields[header]?.title)
                                  return (
                                    <TableHeader key={header}>
                                      {entity.fields[header]?.title.toUpperCase()}
                                    </TableHeader>
                                  );
                              })}
                              <TableHeader></TableHeader>
                            </TableRow>
                          ) : (
                            <TableRow>
                              {tableHeaders.map((header) => (
                                <TableHeader key={header}>
                                  {entity.fields?.[data?.retriveFromKey]?.fields?.[header].title
                                    ? entity.fields?.[data?.retriveFromKey]?.fields?.[
                                        header
                                      ].title.toUpperCase()
                                    : header.toUpperCase()}
                                </TableHeader>
                              ))}
                              <TableHeader></TableHeader>
                            </TableRow>
                          )}
                        </TableHead>
                        <TableBody>
                          {instances.map((_instance, i) => (
                            <TableRow key={i}>
                              {tableHeaders?.map((header, index) => {
                                transformedHeader = header;
                                if (header.startsWith('$')) {
                                  headerWithoutDollarDot = header.substring(
                                    2,
                                    header.lastIndexOf('.'),
                                  );
                                  entityData = entity?.fields[headerWithoutDollarDot]?.fields;
                                  const headers = Object.keys(entityData);
                                  retrivedValue = headers.reduce((result, header) => {
                                    if (
                                      _instance.hasOwnProperty(header) &&
                                      typeof _instance[header] === 'string'
                                    ) {
                                      result.push(_instance[header]);
                                    }
                                    if (_instance[header] === undefined) {
                                      result.push('null');
                                    }

                                    return result;
                                  }, []);
                                } else {
                                  transformedHeader = header.split('.');
                                }
                                const isDateField = entity.fields[header]?.type === 'Date';
                                const isDateTimeField =
                                  entity.fields[header]?.type === 'Date & Time';
                                const isTimeField =
                                  entity.fields[header]?.type === 'Time' ||
                                  entity.fields?.[data?.retriveFromKey]?.fields?.[header]?.type ===
                                    'Time';
                                let transformedHeaderInstance;
                                if (transformedHeader?.length === 2) {
                                  transformedHeaderInstance = _instance[transformedHeader[0]];
                                }
                                header = header?.startsWith('$') ? header.substring(2) : header;
                                if (tableHeaderOverrides && tableHeaderOverrides[header]) {
                                  if (tableHeaderOverrides[header].type === 'badge') {
                                    return (
                                      <TableCell key={index}>
                                        <span
                                          className={`inline-flex items-center rounded-full px-6 py-2 text-normal font-medium ${
                                            tableHeaderOverrides[header].colorMapping[
                                              _instance[header]
                                            ]
                                          }`}
                                        >
                                          {_instance[header]}
                                        </span>
                                      </TableCell>
                                    );
                                  } else if (tableHeaderOverrides[header].type === 'avatar') {
                                    return (
                                      <TableCell key={index}>
                                        <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-indigo-500">
                                          <span className="text-md  leading-none text-white">
                                            {getAvatarText(
                                              transformedHeaderInstance[transformedHeader[1]],
                                            )}
                                          </span>
                                        </span>
                                      </TableCell>
                                    );
                                  } else if (
                                    tableHeaderOverrides?.[header]?.type === 'objectValue'
                                  ) {
                                    return (
                                      <TableCell key={index}>
                                        {
                                          _instance?.[transformedHeader?.[0]]?.[
                                            tableHeaderOverrides?.[header]?.displayValue
                                          ]
                                        }
                                      </TableCell>
                                    );
                                  } else {
                                    return (
                                      <TableCell key={index}>
                                        {transformedHeader.length === 2 && transformedHeaderInstance
                                          ? transformedHeaderInstance[transformedHeader[1]]
                                          : _instance[transformedHeader[0]]}
                                      </TableCell>
                                    );
                                  }
                                } else if (tableSameCellDatas?.[header]) {
                                  return (
                                    <TableCell key={index}>
                                      <div className="flex flex-col gap-2">
                                        <span className="text-base font-semibold text-gray-900">
                                          {renderCells(_instance, tableSameCellDatas, header, i)}
                                        </span>
                                        <span className="text-sm font-medium text-gray-500">
                                          {getFormattedValue(
                                            _instance,
                                            tableSameCellDatas?.[header]?.values[1],
                                          )}
                                        </span>
                                      </div>
                                    </TableCell>
                                  );
                                } else {
                                  return (
                                    <TableCell key={index}>
                                      {(() => {
                                        if (
                                          transformedHeader?.length === 2 &&
                                          transformedHeaderInstance
                                        ) {
                                          return transformedHeaderInstance[transformedHeader[1]];
                                        } else if (isDateField) {
                                          return formatValue(
                                            _instance[transformedHeader[0]],
                                            'DD-MMM-YYYY',
                                            'Date',
                                          );
                                        } else if (isDateTimeField) {
                                          return formatValue(
                                            _instance[transformedHeader[0]],
                                            'DD-MMM-YYYY hh:mm A',
                                            'Date',
                                          );
                                        } else if (isTimeField) {
                                          const valueToFormat = _instance[transformedHeader[0]];
                                          if (valueToFormat !== undefined) {
                                            return formatValue(valueToFormat, 'hh:mm A', 'Date');
                                          }
                                          return null;
                                        } else {
                                          return _instance[transformedHeader[0]];
                                        }
                                      })()}
                                    </TableCell>
                                  );
                                }
                              })}
                              <TableCell>
                                {rowActions?.map((_action, index) => {
                                  return (
                                    <PrimaryLink
                                      className={
                                        _action.type === 'danger' || _action.type === 'delete'
                                          ? 'text-rose-600'
                                          : ''
                                      }
                                      key={index}
                                      onClick={() => {
                                        performAction(_action, _instance, i);
                                      }}
                                    >
                                      {_action.icon ? <Icons data={_action.icon} /> : null}
                                      {_action.title}
                                    </PrimaryLink>
                                  );
                                })}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>

                      <ConfirmationPopup
                        id={rowInstance?._id}
                        title={`Are you sure you want to archive?`}
                        open={showConfirmationPopup}
                        setOpen={setShowConfirmationPopup}
                        buttonName="Archive"
                        onPrimaryButtonClick={() => onArchiveHandler(rowInstance?._id)}
                      />

                      <ConfirmationPopup
                        id={deleteRowIndex}
                        title={`Are you sure you want to delete?`}
                        type={'danger'}
                        open={showDeleteConfirmationPopup}
                        setOpen={setShowDeleteConfirmationPopup}
                        buttonName={'Delete'}
                        onPrimaryButtonClick={() => onDeleteHandler(deleteRowIndex)}
                      />
                    </div>
                    {data?.pagination.limit === '-1' ? null : (
                      <Gupagination
                        data={instances}
                        count={count}
                        updatePagination={handlePageChange}
                      />
                    )}
                    {selectedAction ? (
                      <LayoutFragment
                        params={selectedAction.params}
                        resetState={resetState}
                      ></LayoutFragment>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : searchFilterHasResults && instances.length === 0 ? (
          <div className="px-4 sm:px-6 lg:px-8 mt-3">
            {allowCreateFlag || allowBulkCreateFlag || data?.pageTitle ? (
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h4 className="font-semibold text-slate-900 text-2xl">{data.pageTitle}</h4>
                </div>
                <div className="flex flex-row mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  {allowCreateFlag ? (
                    <div className="flex">
                      <Actions
                        data={{
                          id: 'action_create',
                        }}
                        actions={actions}
                        entity={entity}
                        params={params}
                      />
                    </div>
                  ) : null}
                  {allowBulkCreateFlag ? (
                    <div className="flex">
                      <Actions
                        data={{
                          id: 'action_bulkUpload',
                        }}
                        actions={actions}
                        entity={entity}
                        params={params}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            <div className="mt-8 flow-root">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6">
                  {(() => {
                    if (data?.title) {
                      return (
                        <div className="p-2 divide-y divide-slate-300">
                          <H3>{data?.title}</H3>
                        </div>
                      );
                    }

                    return null;
                  })()}

                  <div className="rounded-lg bg-white shadow p-3">
                    {allowSearch ? (
                      <SearchBar
                        onSearchInputChangeHandler={(e) => {
                          onSearchInputChangeHandler(e);
                          setSearchText(e.target.value);
                        }}
                        searchValue={searchText}
                      />
                    ) : null}
                    <div className="grid place-items-center w-full py-10 bg-gray-100 rounded-xl mt-10">
                      <div className="text-xl font-semibold text-gray-600">No Data Found</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyList
            data={data}
            actions={actions}
            entity={entity}
            params={params}
            showSubText={true}
            allowBulkCreateFlag={allowBulkCreateFlag}
            allowCreateFlag={allowCreateFlag}
          />
        )}
      </>
    );
  } else {
    return data?.showEmptyList ? (
      <EmptyList
        data={data}
        actions={actions}
        entity={entity}
        params={params}
        showSubText={true}
        allowBulkCreateFlag={allowBulkCreateFlag}
        allowCreateFlag={allowCreateFlag}
      />
    ) : (
      <div style={{ width: '400px', margin: 'auto' }}>
        <Lottie animationData={loadingImage} loop={true} />
      </div>
    );
  }
}
