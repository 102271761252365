import React from 'react';
let Icons = {};

Icons['HomeIcon'] = React.lazy(() => import('@heroicons/react/24/outline/HomeIcon'));
Icons['ClipboardDocumentCheckIcon'] = React.lazy(
  () => import('@heroicons/react/24/outline/ClipboardDocumentCheckIcon'),
);
Icons['PlayCircleIcon'] = React.lazy(() => import('@heroicons/react/24/outline/PlayCircleIcon'));
Icons['ShieldCheckIcon'] = React.lazy(() => import('@heroicons/react/24/outline/ShieldCheckIcon'));
Icons['RocketLaunchIcon'] = React.lazy(
  () => import('@heroicons/react/24/outline/RocketLaunchIcon'),
);
Icons['UsersIcon'] = React.lazy(() => import('@heroicons/react/24/outline/UsersIcon'));
Icons['SearchIcon'] = React.lazy(
  () => import('@heroicons/react/24/outline/DocumentMagnifyingGlassIcon'),
);
Icons['DocumentChartBarIcon'] = React.lazy(
  () => import('@heroicons/react/24/outline/DocumentChartBarIcon'),
);
Icons['Cog6ToothIcon'] = React.lazy(() => import('@heroicons/react/24/outline/Cog6ToothIcon'));
Icons['TicketIcon'] = React.lazy(() => import('@heroicons/react/24/outline/TicketIcon'));
Icons['NumberedListIcon'] = React.lazy(
  () => import('@heroicons/react/24/outline/NumberedListIcon'),
);

export default Icons;
